import * as React from "react";
import {
  nextButton,
  cancelChangesButton,
  alignContentView,
} from "./SelectRateComponent.module.scss";
import { Button, Col, Row } from "react-bootstrap";
import NewEditOffers from "../../reservations/NewEditOffers/NewEditOffers";
import NewReservationDetails from "../../reservations/NewReservationDetails/NewReservationDetails";
import useMobileDetect from "../../../hooks/useMobileDetect";
import { setEditReservationCheckout } from "../../../redux/slices/Checkout/editReservationCheckout";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import moment from "moment-mini";
import { customFormatDate, resParseDateFormat } from "../../../services/dates";
import { navigate } from "gatsby";
import NewUpdatedTotal from "../NewUpdatedTotal/NewUpdatedTotal";
export const SelectRateComponent: React.FC = () => {
  const isMobileOnly = useMobileDetect();
  const dispatch = useAppDispatch();
  const checkout: { [x: string | number]: any } = useAppSelector(
    (state) => state.editReservationCheckout
  );
  const handleContinueClick = () => {
    dispatch(
      setEditReservationCheckout({ ...checkout, Step: "select-addons" })
    );
    navigate("/edit-reservation/select-payment");
  };
  const navigateToReservationPage = () => {
    const startDate = checkout.originalReservation.reservation
      ? customFormatDate(checkout.originalReservation.reservation.start)
      : "";
    const endDate = checkout.originalReservation.reservation
      ? customFormatDate(checkout.originalReservation.reservation.end)
      : "";
    navigate("/reservation", {
      state: {
        reservation: {
          crs_reservation_id: checkout.originalReservation.confirmationID,
          arrival: moment(startDate, "YYYY-MM-DD").format(resParseDateFormat),
          departure: moment(endDate, "YYYY-MM-DD").format(resParseDateFormat),
          details: checkout.originalReservation.reservation,
        },
        lastName: checkout.originalReservation.reservation.guests[0].surname,
      },
    });
  };
  return (
    <Row className="mt-lg-4 mt-4 me-0 ms-0 p-0">
      <Col lg={7} className="pe-3">
        <Row className="m-0">
          <NewEditOffers isMember={checkout.isMember} />
        </Row>
        <Row className="pt-4 pb-4 ps-2 pe-2 ps-lg-3 pe-lg-3 rounded">
          <NewUpdatedTotal
            className={"pl-0.75 pr-0.75 pb-2 border-top border-bottom"}
            Heading={"New Total"}
          />
        </Row>
        <Row
          className={`${alignContentView} mt-2 pt-2 pb-2 ps-2 pe-2 ps-lg-3 pe-lg-3 me-0 ms-0 rounded`}
        >
          <Row>
            <Button
              type="submit"
              onClick={handleContinueClick}
              disabled={false}
              className={nextButton}
              id="continue-to-payment-button"
            >
              Continue to Payment
            </Button>
          </Row>
          <Row
            className={`${alignContentView} pt-2 pb-2 ps-2 pe-2 ps-lg-3 pe-lg-3 rounded`}
          >
            <button
              onClick={navigateToReservationPage}
              className={`${cancelChangesButton} g-0 btn btn-link btn-sm`}
              id="cancel-changes-button"
            >
              Cancel Changes
            </button>
          </Row>
        </Row>
        <Row></Row>
      </Col>
      {!isMobileOnly && (
        <Col lg={{ span: 4, offset: 1 }}>
          <NewReservationDetails
            reservation={checkout.originalReservation.reservation}
            hotel={checkout.originalReservation.hotel}
          />
        </Col>
      )}
    </Row>
  );
};
