import React, { useEffect, useState } from "react";
import Layout from "../../components/global/Layout/Layout";
import SEO from "../../components/global/SEO/SEO";
import { useAppSelector } from "../../hooks";
import {
  EditReservationHeader,
  StyledContainer,
} from "../../pageHelpers/EditReservation/EditReservationHelpers";
import useMobileDetect from "../../hooks/useMobileDetect";
import { SelectRateComponent } from "../../components/reservations/SelectRateComponent/SelectRateComponent";
import NewReservationDetails from "../../components/reservations/NewReservationDetails/NewReservationDetails";
import { isEmpty } from "lodash";
import { navigate } from "gatsby";
const SelectRate: React.FC = () => {
  const isMobile = useMobileDetect();
  const editReservationCheckout = useAppSelector(
    (state) => state.editReservationCheckout
  );
  const [showModal, setShowModal] = useState(false);

  const reservation =
    editReservationCheckout?.originalReservation?.reservation || null;
  const hotel = editReservationCheckout?.originalReservation?.hotel || null;

  useEffect(() => {
    if (isEmpty(reservation)) {
      navigate(-1);
    }
  }, []);

  const handleChangeDate = () => {
    setShowModal(!showModal);
  };

  return (
    <Layout isEditReservationPage={true}>
      <SEO
        title={"Edit Reservation"}
        koddiTitle="Edit Reservation"
        noIndex={true}
        noFollow={true}
      />

      {reservation && hotel ? (
        <>
          {isMobile && (
            <NewReservationDetails
              step={"select-rate"}
              reservation={reservation}
              hotel={hotel}
              handleChangeDate={handleChangeDate}
            />
          )}
          <StyledContainer>
            <EditReservationHeader step={"select-rate"} />
            <SelectRateComponent />
          </StyledContainer>
        </>
      ) : (
        <div>Please provide reservation details</div>
      )}
    </Layout>
  );
};

export default SelectRate;
